"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SwitchPricingEvent_1 = require("./SwitchPricingEvent");
var SwitchPricingNormalizer = /** @class */ (function () {
    function SwitchPricingNormalizer() {
    }
    SwitchPricingNormalizer.prototype.normalize = function (element, catalog) {
        var switchPricingEvent = new SwitchPricingEvent_1.default();
        switchPricingEvent.pricing = element;
        return switchPricingEvent;
    };
    SwitchPricingNormalizer.prototype.support = function (element, catalog) {
        return true;
    };
    return SwitchPricingNormalizer;
}());
exports.default = SwitchPricingNormalizer;
