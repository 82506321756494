"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AbstractDataBuilder = /** @class */ (function () {
    function AbstractDataBuilder(normalizersResolver) {
        this.normalizersResolver = normalizersResolver;
    }
    AbstractDataBuilder.create = function () {
        throw new Error("Builder create method not implemented !");
    };
    AbstractDataBuilder.prototype.build = function (element, context) {
        var normalizer = this.normalizersResolver.resolve(element, context);
        if (null !== normalizer) {
            return normalizer.normalize(element, context);
        }
        return null;
    };
    return AbstractDataBuilder;
}());
exports.default = AbstractDataBuilder;
