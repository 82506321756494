"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SelectPromotionEvent = /** @class */ (function () {
    function SelectPromotionEvent() {
    }
    SelectPromotionEvent.prototype.export = function () {
        return {
            'promotion_id': this.promotionId,
            'promotion_name': this.promotionName
        };
    };
    return SelectPromotionEvent;
}());
exports.default = SelectPromotionEvent;
