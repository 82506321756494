"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SortOutEvent_1 = require("./SortOutEvent");
var SortOutNormalizer = /** @class */ (function () {
    function SortOutNormalizer() {
    }
    SortOutNormalizer.prototype.normalize = function (element, catalog) {
        var sortOutEvent = new SortOutEvent_1.default();
        sortOutEvent.sortOutType = element;
        return sortOutEvent;
    };
    SortOutNormalizer.prototype.support = function (element, catalog) {
        return true;
    };
    return SortOutNormalizer;
}());
exports.default = SortOutNormalizer;
