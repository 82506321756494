"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FeatureFlag_1 = require("../FeatureFlag");
var GoogleAnalyticsProcessor_1 = require("./GoogleAnalyticsProcessor");
var SelectItemDataBuilder_1 = require("./selectItem/SelectItemDataBuilder");
var ViewItemDataBuilder_1 = require("./viewItem/ViewItemDataBuilder");
var ViewItemListDataBuilder_1 = require("./viewItemList/ViewItemListDataBuilder");
var AddToCartDataBuilder_1 = require("./addToCart/AddToCartDataBuilder");
var AddToQuotationDataBuilder_1 = require("./addToQuotation/AddToQuotationDataBuilder");
var AddToWishlistDataBuilder_1 = require("./addToWishlist/AddToWishlistDataBuilder");
var BeginCheckoutDataBuilder_1 = require("./beginCheckout/BeginCheckoutDataBuilder");
var CheckAvailabilityDataBuilder_1 = require("./checkAvailability/CheckAvailabilityDataBuilder");
var CheckoutProgressDataBuilder_1 = require("./checkoutProgress/CheckoutProgressDataBuilder");
var PurchaseDataBuilder_1 = require("./purchase/PurchaseDataBuilder");
var RemoveFromCartDataBuilder_1 = require("./removeFromCart/RemoveFromCartDataBuilder");
var SelectPromotionDataBuilder_1 = require("./selectPromition/SelectPromotionDataBuilder");
var SwitchPricingDataBuilder_1 = require("./switchPricing/SwitchPricingDataBuilder");
var FilterDataBuilder_1 = require("./filter/FilterDataBuilder");
var SortOutDataBuilder_1 = require("./sortOut/SortOutDataBuilder");
var ClickMatchFamilyDataBuilder_1 = require("./clickMatchFamily/ClickMatchFamilyDataBuilder");
var ClickSearchCategoryDataBuilder_1 = require("./clickSearchCategory/ClickSearchCategoryDataBuilder");
var GaEventHandler = /** @class */ (function () {
    function GaEventHandler() {
    }
    GaEventHandler.viewItemList = function (elements, category) {
        if (false === FeatureFlag_1.FeatureFlag.isGaEnabled()) {
            return;
        }
        var builder = ViewItemListDataBuilder_1.default.create();
        var dto = builder.build(elements, category);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('view_item_list', dto.export());
    };
    GaEventHandler.selectItem = function (elements, category) {
        if (false === FeatureFlag_1.FeatureFlag.isGaEnabled()) {
            return;
        }
        var builder = SelectItemDataBuilder_1.default.create();
        var dto = builder.build(elements, category);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('select_item', dto.export());
    };
    GaEventHandler.viewItem = function (elements, category) {
        if (false === FeatureFlag_1.FeatureFlag.isGaEnabled()) {
            return;
        }
        var builder = ViewItemDataBuilder_1.default.create();
        var dto = builder.build(elements, category);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('view_item', dto.export());
    };
    GaEventHandler.addToCart = function (elements, category) {
        if (false === FeatureFlag_1.FeatureFlag.isGaEnabled()) {
            return;
        }
        var builder = AddToCartDataBuilder_1.default.create();
        var dto = builder.build(elements, category);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('add_to_cart', dto.export());
    };
    // cart
    GaEventHandler.removeFromCart = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = RemoveFromCartDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('remove_from_cart', dto.export());
    };
    GaEventHandler.beginCheckout = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = BeginCheckoutDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('begin_checkout', dto.export());
    };
    GaEventHandler.checkoutProgress = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = CheckoutProgressDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('checkout_progress', dto.export());
    };
    GaEventHandler.checkAvailability = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = CheckAvailabilityDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('check_availability', dto.export());
    };
    GaEventHandler.addToWishList = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = AddToWishlistDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('add_to_wishlist', dto.export());
    };
    GaEventHandler.removeFromWishList = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = RemoveFromCartDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('remove_from_wishlist', dto.export());
    };
    GaEventHandler.addToQuotation = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = AddToQuotationDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('add_to_quotation', dto.export());
    };
    GaEventHandler.purchase = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = PurchaseDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('purchase', dto.export());
    };
    GaEventHandler.selectPromotion = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = SelectPromotionDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('select_promotion', dto.export());
    };
    GaEventHandler.switchPricing = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = SwitchPricingDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('switch_pricing', dto.export());
    };
    GaEventHandler.filter = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = FilterDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('filter', dto.export());
    };
    GaEventHandler.sortOut = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = SortOutDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('sort_out', dto.export());
    };
    GaEventHandler.ClickMatchFamily = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ClickMatchFamilyDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('click_match_family', dto.export());
    };
    GaEventHandler.clickEquivalentProduct = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('click_equivalent_product', []);
    };
    GaEventHandler.clickSearchCategory = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ClickSearchCategoryDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        GoogleAnalyticsProcessor_1.GoogleAnalyticsProcessor.track('click_search_category', dto.export());
    };
    return GaEventHandler;
}());
exports.default = GaEventHandler;
