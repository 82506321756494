"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var utilities_1 = require("../../utilities");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var AddToCartEvent_1 = require("./AddToCartEvent");
var AddToCartFromFolderNormalizer = /** @class */ (function () {
    function AddToCartFromFolderNormalizer() {
    }
    AddToCartFromFolderNormalizer.prototype.normalize = function (elements, context) {
        var addToCartEvent = new AddToCartEvent_1.default();
        $('td .folder-chosen').each(function (index, value) {
            var gaDiv = $(value).closest('td').find(constants_1.GA_CLASS);
            if (1 === gaDiv.length) {
                var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
                item.itemPricing = (0, utilities_1.getModePrice)();
                item.index = 1;
                addToCartEvent.itemCollection.addItem(item);
            }
        });
        var offCatalogClass = ".ref-oe-container.bloc-reference.folder-offcatalog";
        $(offCatalogClass + ' .folder-chosen').each(function (index, value) {
            var gaDiv = $(value).closest(offCatalogClass).find(constants_1.GA_CLASS);
            if (1 === gaDiv.length) {
                var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
                item.itemPricing = (0, utilities_1.getModePrice)();
                item.index = 1;
                addToCartEvent.itemCollection.addItem(item);
            }
        });
        return addToCartEvent;
    };
    AddToCartFromFolderNormalizer.prototype.support = function (element, context) {
        return constants_1.FOLDER_COST === context;
    };
    return AddToCartFromFolderNormalizer;
}());
exports.default = AddToCartFromFolderNormalizer;
