"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ItemCollection_1 = require("../dto/item/ItemCollection");
var ViewItemEvent = /** @class */ (function () {
    function ViewItemEvent() {
        this.currency = 'EUR';
        this.itemCollection = new ItemCollection_1.ItemCollection();
    }
    ViewItemEvent.prototype.export = function () {
        return {
            currency: this.currency,
            items: this.itemCollection.export(),
        };
    };
    return ViewItemEvent;
}());
exports.default = ViewItemEvent;
