"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClickSearchCategoryEvent = /** @class */ (function () {
    function ClickSearchCategoryEvent() {
    }
    ClickSearchCategoryEvent.prototype.export = function () {
        return {
            category: this.category,
        };
    };
    return ClickSearchCategoryEvent;
}());
exports.default = ClickSearchCategoryEvent;
