"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vehicle = void 0;
var Vehicle = /** @class */ (function () {
    function Vehicle() {
        this.vehiculeImmat = null;
        this.vehiculeBrand = null;
        this.vehiculeModel = null;
        this.vehicleVersion = null;
        this.vehicleDateMec = null;
        this.vehicleNumord = null;
        this.vehicleKtype = null;
        this.vehicleVin = null;
        this.vehicleCodmar = null;
        this.vehicleCodemodel = null;
        this.vehicleMid = null;
        this.vehicleYear = null;
    }
    Vehicle.prototype.export = function () {
        return {
            vehicule_immat: this.vehiculeImmat,
            vehicule_brand: this.vehiculeBrand,
            vehicule_model: this.vehiculeModel,
            vehicle_version: this.vehicleVersion,
            vehicle_date_mec: this.vehicleDateMec,
            vehicle_numord: this.vehicleNumord,
            vehicle_ktype: this.vehicleKtype,
            vehicle_vin: this.vehicleVin,
            vehicle_codmar: this.vehicleCodmar,
            vehicle_codemodel: this.vehicleCodemodel,
            vehicle_mid: this.vehicleMid,
            vehicle_year: this.vehicleYear,
        };
    };
    return Vehicle;
}());
exports.Vehicle = Vehicle;
