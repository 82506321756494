"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var PurchaseEvent_1 = require("./PurchaseEvent");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var utilities_1 = require("../../utilities");
var PurchaseNormalizer = /** @class */ (function () {
    function PurchaseNormalizer() {
    }
    PurchaseNormalizer.prototype.normalize = function (elements, origin) {
        var _a;
        var purchaseEvent = new PurchaseEvent_1.default();
        var table = $('table#table-order-' + origin);
        if (0 === table.length) {
            return null;
        }
        var gaDivs = $(table).find(constants_1.GA_CLASS);
        $(gaDivs).each(function (index, gaDiv) {
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = (0, utilities_1.getModePrice)();
            purchaseEvent.itemCollection.addItem(item);
        });
        purchaseEvent.movexTransactionId = (_a = table.attr('data-movex-orno')) !== null && _a !== void 0 ? _a : null;
        Object.assign(purchaseEvent, this.getTableData(table));
        return purchaseEvent;
    };
    PurchaseNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    PurchaseNormalizer.prototype.getTableData = function (table) {
        var _a;
        return {
            movexTransactionId: (_a = table.attr('data-movex-orno')) !== null && _a !== void 0 ? _a : null,
            shipping: null,
            transactionId: table.attr('data-numCde'),
            value: table.attr('data-total-amount'),
        };
    };
    return PurchaseNormalizer;
}());
exports.default = PurchaseNormalizer;
