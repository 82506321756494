"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ItemCollection_1 = require("../dto/item/ItemCollection");
var ViewItemListEvent = /** @class */ (function () {
    function ViewItemListEvent() {
        this.currency = 'EUR';
        this.filterType = '';
        this.itemNumber = 0;
        this.sortOutType = '';
        this.itemCollection = new ItemCollection_1.ItemCollection();
    }
    ViewItemListEvent.prototype.export = function () {
        return {
            currency: this.currency,
            filter_type: this.filterType,
            item_number: this.itemNumber,
            items: this.itemCollection.export(),
            sort_out_type: this.sortOutType,
        };
    };
    return ViewItemListEvent;
}());
exports.default = ViewItemListEvent;
