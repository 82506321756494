"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemFactory = void 0;
var Item_1 = require("./Item");
var ItemFactory = /** @class */ (function () {
    function ItemFactory() {
    }
    ItemFactory.createFromDataAttribute = function (element) {
        var itemDto = new Item_1.default();
        itemDto.itemName = element.data('item-name');
        itemDto.itemId = element.data('item-id');
        itemDto.itemAvailability = element.data('item-availability');
        itemDto.itemPricing = element.data('item-pricing');
        itemDto.itemCategory = element.data('item-category');
        itemDto.itemCategory2 = element.data('item-category2');
        itemDto.promotionId = element.data('promotion-id');
        itemDto.promotionName = element.data('promotion-name');
        itemDto.index = element.data('index');
        itemDto.quantity = element.data('quantity');
        itemDto.price = element.data('price');
        itemDto.priceTarif = element.data('price-tarif');
        itemDto.pricePromo = element.data('price-promo');
        itemDto.itemListName = element.data('item-list-name');
        itemDto.refEquip = element.data('ref-equip');
        return itemDto;
    };
    ItemFactory.createFromJsonString = function (itemString) {
        var itemDto = new Item_1.default();
        try {
            var itemObject = JSON.parse(itemString);
            itemDto.itemName = itemObject.itemName;
            itemDto.itemId = itemObject.itemId;
            itemDto.itemAvailability = itemObject.itemAvailability;
            itemDto.itemPricing = itemObject.itemPricing;
            itemDto.itemCategory = itemObject.itemCategory;
            itemDto.itemCategory2 = itemObject.itemCategory2;
            itemDto.promotionId = itemObject.promotionId;
            itemDto.promotionName = itemObject.promotionName;
            itemDto.index = itemObject.index;
            itemDto.quantity = itemObject.quantity;
            itemDto.price = itemObject.price;
            itemDto.priceTarif = itemObject.priceTarif;
            itemDto.pricePromo = itemObject.pricePromo;
            itemDto.itemListName = itemObject.itemListName;
            itemDto.refEquip = itemObject.refEquip;
        }
        catch (err) {
            // For syntax error
        }
        return itemDto;
    };
    return ItemFactory;
}());
exports.ItemFactory = ItemFactory;
