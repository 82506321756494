"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SortOutEvent = /** @class */ (function () {
    function SortOutEvent() {
    }
    SortOutEvent.prototype.export = function () {
        return {
            sort_out_type: this.sortOutType.trim(),
        };
    };
    return SortOutEvent;
}());
exports.default = SortOutEvent;
