"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ItemCollection_1 = require("../dto/item/ItemCollection");
var SelectItemEvent = /** @class */ (function () {
    function SelectItemEvent() {
        this.currency = 'EUR';
        this.itemCollection = new ItemCollection_1.ItemCollection();
    }
    SelectItemEvent.prototype.export = function () {
        return {
            currency: this.currency,
            items: this.itemCollection.export(),
        };
    };
    return SelectItemEvent;
}());
exports.default = SelectItemEvent;
