"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SwitchPricingEvent = /** @class */ (function () {
    function SwitchPricingEvent() {
    }
    SwitchPricingEvent.prototype.export = function () {
        return {
            pricing: this.pricing,
        };
    };
    return SwitchPricingEvent;
}());
exports.default = SwitchPricingEvent;
