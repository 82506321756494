"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemCollection = void 0;
var ItemCollection = /** @class */ (function () {
    function ItemCollection() {
        this.items = [];
    }
    ItemCollection.prototype.addItem = function (item) {
        this.items.push(item);
        return this;
    };
    ItemCollection.prototype.export = function () {
        var exportedItems = [];
        for (var _i = 0, _a = this.items; _i < _a.length; _i++) {
            var item = _a[_i];
            exportedItems.push(this.exportItem(item));
        }
        return exportedItems;
    };
    ItemCollection.prototype.length = function () {
        return this.items.length;
    };
    ItemCollection.prototype.exportItem = function (item) {
        return {
            'item_name': item.itemName,
            'item_id': item.itemId,
            'item_availability': item.itemAvailability,
            'item_pricing': item.itemPricing,
            'item_category': item.itemCategory,
            'item_category2': item.itemCategory2,
            'promotion_id': item.promotionId,
            'promotion_name': item.promotionName,
            'index': item.index,
            'quantity': item.quantity,
            'price': item.price,
            'price_tarif': item.priceTarif,
            'price_promo': item.pricePromo,
            'item_list_name': item.itemListName,
            'ref_equip': item.refEquip,
        };
    };
    return ItemCollection;
}());
exports.ItemCollection = ItemCollection;
