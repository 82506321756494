"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var RemoveFromCartEvent_1 = require("./RemoveFromCartEvent");
var utilities_1 = require("../../utilities");
var RemoveFromCartDetailsNormalizer = /** @class */ (function () {
    function RemoveFromCartDetailsNormalizer() {
    }
    RemoveFromCartDetailsNormalizer.prototype.normalize = function (elements, origin) {
        var removeFromCartEvent = new RemoveFromCartEvent_1.default();
        var parentDiv = $(elements).closest('article');
        if (1 === parentDiv.length) {
            var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = 1;
            item.itemPricing = (0, utilities_1.getModePrice)();
            removeFromCartEvent.itemCollection.addItem(item);
            return removeFromCartEvent;
        }
        var gaDivs = $('article ' + constants_1.GA_CLASS);
        $(gaDivs).each(function (index, gaDiv) {
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = (0, utilities_1.getModePrice)();
            removeFromCartEvent.itemCollection.addItem(item);
        });
        return removeFromCartEvent;
    };
    RemoveFromCartDetailsNormalizer.prototype.support = function (element, origin) {
        return 'cart_details' === origin;
    };
    return RemoveFromCartDetailsNormalizer;
}());
exports.default = RemoveFromCartDetailsNormalizer;
