"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var RemoveFromCartEvent_1 = require("./RemoveFromCartEvent");
var utilities_1 = require("../../utilities");
var RemoveFromFolderCostNormalizer = /** @class */ (function () {
    function RemoveFromFolderCostNormalizer() {
    }
    RemoveFromFolderCostNormalizer.prototype.normalize = function (elements, origin) {
        var removeFromCartEvent = new RemoveFromCartEvent_1.default();
        var gaDivs = $(elements)
            .closest(':has(.ga)')
            .find(".ga");
        $(gaDivs).each(function (index, gaDiv) {
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = (0, utilities_1.getModePrice)();
            removeFromCartEvent.itemCollection.addItem(item);
        });
        return removeFromCartEvent;
    };
    RemoveFromFolderCostNormalizer.prototype.support = function (element, origin) {
        return constants_1.FOLDER_COST === origin;
    };
    return RemoveFromFolderCostNormalizer;
}());
exports.default = RemoveFromFolderCostNormalizer;
