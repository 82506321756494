"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AddToQuotationEvent_1 = require("./AddToQuotationEvent");
var utilities_1 = require("../../utilities");
var constants_1 = require("../../constants");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var AddToQuotationNormalizer = /** @class */ (function () {
    function AddToQuotationNormalizer() {
    }
    AddToQuotationNormalizer.prototype.normalize = function (elements, origin) {
        var addToQuotationEvent = new AddToQuotationEvent_1.default();
        var parentDiv = $(elements).closest(':has(.ga)');
        if (1 !== parentDiv.length) {
            return addToQuotationEvent;
        }
        var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
        if (1 !== gaDiv.length) {
            return addToQuotationEvent;
        }
        var item = ItemFactory_1.ItemFactory.createFromDataAttribute(gaDiv);
        item.itemPricing = (0, utilities_1.getModePrice)();
        item.index = 1;
        addToQuotationEvent.itemCollection.addItem(item);
        return addToQuotationEvent;
    };
    AddToQuotationNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return AddToQuotationNormalizer;
}());
exports.default = AddToQuotationNormalizer;
