"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ItemFactory_1 = require("../dto/item/ItemFactory");
var utilities_1 = require("../../utilities");
var ViewItemEvent_1 = require("./ViewItemEvent");
var constants_1 = require("../../constants");
var ViewItemNormalizer = /** @class */ (function () {
    function ViewItemNormalizer() {
    }
    ViewItemNormalizer.prototype.normalize = function (element, catalog) {
        var viewItemEvent = new ViewItemEvent_1.default();
        var parentDiv = $(element).closest(':has(.ga)');
        if (1 !== parentDiv.length) {
            return viewItemEvent;
        }
        var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
        if (1 !== gaDiv.length) {
            return viewItemEvent;
        }
        var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
        item.itemPricing = (0, utilities_1.getModePrice)();
        item.index = 1;
        viewItemEvent.itemCollection.addItem(item);
        return viewItemEvent;
    };
    ViewItemNormalizer.prototype.support = function (element, catalog) {
        return true;
    };
    return ViewItemNormalizer;
}());
exports.default = ViewItemNormalizer;
