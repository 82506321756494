"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var BeginCheckoutEvent_1 = require("./BeginCheckoutEvent");
var utilities_1 = require("../../utilities");
var BeginCheckoutCartLayerNormalizer = /** @class */ (function () {
    function BeginCheckoutCartLayerNormalizer() {
    }
    BeginCheckoutCartLayerNormalizer.prototype.normalize = function (elements, origin) {
        var beginCheckoutEvent = new BeginCheckoutEvent_1.default();
        beginCheckoutEvent.transmissionType = 'transmission';
        var gaDivs = $('#layerPanier ' + constants_1.GA_CLASS);
        $(gaDivs).each(function (index, gaDiv) {
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = (0, utilities_1.getModePrice)();
            beginCheckoutEvent.itemCollection.addItem(item);
        });
        return beginCheckoutEvent;
    };
    BeginCheckoutCartLayerNormalizer.prototype.support = function (element, origin) {
        return 'cart-layer' === origin;
    };
    return BeginCheckoutCartLayerNormalizer;
}());
exports.default = BeginCheckoutCartLayerNormalizer;
