"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClickSearchCategoryEvent_1 = require("./ClickSearchCategoryEvent");
var manage_ga_1 = require("./../../../manage-ga");
var ClickSearchCategoryNormalizer = /** @class */ (function () {
    function ClickSearchCategoryNormalizer() {
    }
    ClickSearchCategoryNormalizer.prototype.normalize = function (element, catalog) {
        var clickSearchCategoryEvent = new ClickSearchCategoryEvent_1.default();
        var pathNav = $(element).find('a').attr('href');
        clickSearchCategoryEvent.category = (0, manage_ga_1.getCategory)(pathNav);
        return clickSearchCategoryEvent;
    };
    ClickSearchCategoryNormalizer.prototype.support = function (element, catalog) {
        return true;
    };
    return ClickSearchCategoryNormalizer;
}());
exports.default = ClickSearchCategoryNormalizer;
