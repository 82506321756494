"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var RemoveFromCartEvent_1 = require("./RemoveFromCartEvent");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var utilities_1 = require("../../utilities");
var RemoveFromCartNormalizer = /** @class */ (function () {
    function RemoveFromCartNormalizer() {
    }
    RemoveFromCartNormalizer.prototype.normalize = function (elements, origin) {
        var removeFromCartEvent = new RemoveFromCartEvent_1.default();
        $(elements).each(function (index, element) {
            var parentDiv = $(element).closest(':has(.ga)');
            if (0 === parentDiv.length) {
                return;
            }
            var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
            if (0 === gaDiv.length) {
                return;
            }
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
            item.index = index + 1;
            item.itemPricing = (0, utilities_1.getModePrice)();
            removeFromCartEvent.itemCollection.addItem(item);
            // ItemAttributes.updateLayerAvailabilityForAmplitude(parentDiv, gaDiv).done(() => {
            //     const item = ItemFactory.createFromDataAttribute($(gaDiv));
            //     removeFromCartEvent.itemCollection.addItem(item);
            // });
        });
        return removeFromCartEvent;
    };
    RemoveFromCartNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return RemoveFromCartNormalizer;
}());
exports.default = RemoveFromCartNormalizer;
