"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SelectPromotionEvent_1 = require("./SelectPromotionEvent");
var SelectPromotionNormalizer = /** @class */ (function () {
    function SelectPromotionNormalizer() {
    }
    SelectPromotionNormalizer.prototype.normalize = function (element, context) {
        var selectPromotionEvent = new SelectPromotionEvent_1.default();
        var id = $(element).find('a.zoom-button-promo').data('rel');
        var promotionName = $(element).parent().find('h4.title').text();
        selectPromotionEvent.promotionId = id !== null && id !== void 0 ? id : '';
        selectPromotionEvent.promotionName = promotionName !== null && promotionName !== void 0 ? promotionName : '';
        return selectPromotionEvent;
    };
    SelectPromotionNormalizer.prototype.support = function (element, context) {
        return 'tab-panel' === context;
    };
    return SelectPromotionNormalizer;
}());
exports.default = SelectPromotionNormalizer;
