"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ItemFactory_1 = require("../dto/item/ItemFactory");
var utilities_1 = require("../../utilities");
var AddToCartEvent_1 = require("./AddToCartEvent");
var AddToCartFromQuoteNormalizer = /** @class */ (function () {
    function AddToCartFromQuoteNormalizer() {
    }
    AddToCartFromQuoteNormalizer.prototype.normalize = function (elements, context) {
        var addToCartEvent = new AddToCartEvent_1.default();
        var gaDivs = $('table.quotes-result--results-list .ga');
        $(gaDivs).each(function (index, gaDiv) {
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
            item.itemPricing = (0, utilities_1.getModePrice)();
            item.index = 1;
            addToCartEvent.itemCollection.addItem(item);
        });
        return addToCartEvent;
    };
    AddToCartFromQuoteNormalizer.prototype.support = function (element, context) {
        return "devis entretien" === context;
    };
    return AddToCartFromQuoteNormalizer;
}());
exports.default = AddToCartFromQuoteNormalizer;
