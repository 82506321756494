"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ItemFactory_1 = require("../dto/item/ItemFactory");
var utilities_1 = require("../../utilities");
var CheckAvailabilityEvent_1 = require("./CheckAvailabilityEvent");
var constants_1 = require("../../constants");
var CheckAvailabilityNormalizer = /** @class */ (function () {
    function CheckAvailabilityNormalizer() {
    }
    CheckAvailabilityNormalizer.prototype.normalize = function (element, catalog) {
        var selectItemEvent = new CheckAvailabilityEvent_1.default();
        var parentDiv = $(element).closest(':has(.ga)');
        if (1 !== parentDiv.length) {
            return selectItemEvent;
        }
        var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
        if (1 !== gaDiv.length) {
            return selectItemEvent;
        }
        var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
        item.itemPricing = (0, utilities_1.getModePrice)();
        item.index = 1;
        selectItemEvent.itemCollection.addItem(item);
        return selectItemEvent;
    };
    CheckAvailabilityNormalizer.prototype.support = function (element, catalog) {
        return true;
    };
    return CheckAvailabilityNormalizer;
}());
exports.default = CheckAvailabilityNormalizer;
