"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SelectPromotionEvent_1 = require("./SelectPromotionEvent");
var SelectPromotionImageFinderNormalizer = /** @class */ (function () {
    function SelectPromotionImageFinderNormalizer() {
    }
    SelectPromotionImageFinderNormalizer.prototype.normalize = function (element, context) {
        var selectPromotionEvent = new SelectPromotionEvent_1.default();
        var img = $(element).find('img:first');
        var id = $(img).data('id');
        var promotionName = $(img).attr('alt');
        selectPromotionEvent.promotionId = id !== null && id !== void 0 ? id : '';
        selectPromotionEvent.promotionName = promotionName !== null && promotionName !== void 0 ? promotionName : '';
        return selectPromotionEvent;
    };
    SelectPromotionImageFinderNormalizer.prototype.support = function (element, context) {
        return 'image-finder' === context;
    };
    return SelectPromotionImageFinderNormalizer;
}());
exports.default = SelectPromotionImageFinderNormalizer;
