"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClickMatchFamilyEvent_1 = require("./ClickMatchFamilyEvent");
var ClickMatchFamilyNormalizer = /** @class */ (function () {
    function ClickMatchFamilyNormalizer() {
    }
    ClickMatchFamilyNormalizer.prototype.normalize = function (element, catalog) {
        var clickMatchFamilyEvent = new ClickMatchFamilyEvent_1.default();
        var href = $(element).attr('href').split('/');
        clickMatchFamilyEvent.codeFamMatch = href[href.length - 1];
        return clickMatchFamilyEvent;
    };
    ClickMatchFamilyNormalizer.prototype.support = function (element, catalog) {
        return true;
    };
    return ClickMatchFamilyNormalizer;
}());
exports.default = ClickMatchFamilyNormalizer;
