"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ViewItemListEvent_1 = require("./ViewItemListEvent");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var utilities_1 = require("../../utilities");
var TyresViewItemListNormalizer = /** @class */ (function () {
    function TyresViewItemListNormalizer() {
    }
    TyresViewItemListNormalizer.prototype.normalize = function (elements, catalog) {
        var viewItemListEvent = new ViewItemListEvent_1.default();
        var gaDivs = $(elements).find('.ga');
        $(gaDivs).each(function (index, gaDiv) {
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
            item.itemPricing = (0, utilities_1.getModePrice)();
            item.index = index + 1;
            viewItemListEvent.itemCollection.addItem(item);
        });
        viewItemListEvent.itemNumber = viewItemListEvent.itemCollection.length();
        viewItemListEvent.sortOutType = 'dispo_price';
        return viewItemListEvent;
    };
    TyresViewItemListNormalizer.prototype.support = function (element, catalog) {
        return 'pneus' === catalog;
    };
    return TyresViewItemListNormalizer;
}());
exports.default = TyresViewItemListNormalizer;
