"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PurchaseEvent_1 = require("./PurchaseEvent");
var PurchasePunchoutNormalizer = /** @class */ (function () {
    function PurchasePunchoutNormalizer() {
    }
    PurchasePunchoutNormalizer.prototype.normalize = function (elements, origin) {
        var _a;
        var purchaseEvent = new PurchaseEvent_1.default();
        var div = $('#download-cart');
        if (0 === div.length) {
            return null;
        }
        purchaseEvent.movexTransactionId = (_a = div.attr('data-movex-orno')) !== null && _a !== void 0 ? _a : null;
        Object.assign(purchaseEvent, this.getTableData(div));
        return purchaseEvent;
    };
    PurchasePunchoutNormalizer.prototype.support = function (element, origin) {
        return 'punchout' === origin;
    };
    PurchasePunchoutNormalizer.prototype.getTableData = function (div) {
        return {
            movexTransactionId: null,
            shipping: null,
            transactionId: div.attr('data-numCde'),
            value: div.attr('data-total-amount'),
        };
    };
    return PurchasePunchoutNormalizer;
}());
exports.default = PurchasePunchoutNormalizer;
