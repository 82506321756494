"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FilterEvent_1 = require("./FilterEvent");
var FilterNormalizer = /** @class */ (function () {
    function FilterNormalizer() {
    }
    FilterNormalizer.prototype.normalize = function (element, catalog) {
        var filterEvent = new FilterEvent_1.default();
        filterEvent.filterType = element;
        return filterEvent;
    };
    FilterNormalizer.prototype.support = function (element, catalog) {
        return true;
    };
    return FilterNormalizer;
}());
exports.default = FilterNormalizer;
