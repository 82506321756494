"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleAnalyticsProcessor = void 0;
var FeatureFlag_1 = require("../FeatureFlag");
var GoogleAnalyticsProcessor = /** @class */ (function () {
    function GoogleAnalyticsProcessor() {
    }
    GoogleAnalyticsProcessor.track = function (event, data) {
        if (FeatureFlag_1.FeatureFlag.isGaEnabled()) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'ecommerce': null
            });
            window.dataLayer.push({
                event: event,
                'ecommerce': data
            });
        }
    };
    return GoogleAnalyticsProcessor;
}());
exports.GoogleAnalyticsProcessor = GoogleAnalyticsProcessor;
