"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ItemCollection_1 = require("../dto/item/ItemCollection");
var CheckAvailabilityEvent = /** @class */ (function () {
    function CheckAvailabilityEvent() {
        this.currency = 'EUR';
        this.itemCollection = new ItemCollection_1.ItemCollection();
    }
    CheckAvailabilityEvent.prototype.export = function () {
        return {
            currency: this.currency,
            items: this.itemCollection.export(),
        };
    };
    return CheckAvailabilityEvent;
}());
exports.default = CheckAvailabilityEvent;
