"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClickMatchFamilyEvent = /** @class */ (function () {
    function ClickMatchFamilyEvent() {
    }
    ClickMatchFamilyEvent.prototype.export = function () {
        return {
            code_fam_match: this.codeFamMatch,
        };
    };
    return ClickMatchFamilyEvent;
}());
exports.default = ClickMatchFamilyEvent;
