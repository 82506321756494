"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SortOutEvent_1 = require("./SortOutEvent");
var SortOutSortTypeNormalizer = /** @class */ (function () {
    function SortOutSortTypeNormalizer() {
    }
    SortOutSortTypeNormalizer.prototype.normalize = function (element, catalog) {
        var sortOutEvent = new SortOutEvent_1.default();
        var upSort = element.prev('.sortName');
        var downSort = element.prev().prev('.sortName');
        sortOutEvent.sortOutType = upSort.length === 0 ? downSort.text() : upSort.text();
        return sortOutEvent;
    };
    SortOutSortTypeNormalizer.prototype.support = function (element, catalog) {
        return 'sort-type' === catalog;
    };
    return SortOutSortTypeNormalizer;
}());
exports.default = SortOutSortTypeNormalizer;
