"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AbstractNormalizersResolver = /** @class */ (function () {
    function AbstractNormalizersResolver() {
    }
    AbstractNormalizersResolver.prototype.getRegisteredNormalizers = function () {
        if (undefined === this.normalizers) {
            this.normalizers = this.register();
        }
        return this.normalizers;
    };
    AbstractNormalizersResolver.prototype.resolve = function (element, context) {
        var resolvedNormalizer = null;
        for (var _i = 0, _a = this.getRegisteredNormalizers(); _i < _a.length; _i++) {
            var normalizer = _a[_i];
            if (normalizer.support(element, context)) {
                resolvedNormalizer = normalizer;
                break;
            }
        }
        return resolvedNormalizer;
    };
    return AbstractNormalizersResolver;
}());
exports.default = AbstractNormalizersResolver;
