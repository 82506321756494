"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AddToQuotationEvent_1 = require("./AddToQuotationEvent");
var utilities_1 = require("../../utilities");
var constants_1 = require("../../constants");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var AddToQuotationMultipleNormalizer = /** @class */ (function () {
    function AddToQuotationMultipleNormalizer() {
    }
    AddToQuotationMultipleNormalizer.prototype.normalize = function (elements, origin) {
        var addToQuotationEvent = new AddToQuotationEvent_1.default();
        $(elements).each(function (index, element) {
            var parentDiv = $(element).closest(':has(.ga)');
            if (1 !== parentDiv.length) {
                return false;
            }
            var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
            if (1 !== gaDiv.length) {
                return false;
            }
            var item = ItemFactory_1.ItemFactory.createFromDataAttribute(gaDiv);
            item.itemPricing = (0, utilities_1.getModePrice)();
            item.index = index + 1;
            addToQuotationEvent.itemCollection.addItem(item);
        });
        return addToQuotationEvent;
    };
    AddToQuotationMultipleNormalizer.prototype.support = function (element, origin) {
        return origin == 'multiple-favorites';
    };
    return AddToQuotationMultipleNormalizer;
}());
exports.default = AddToQuotationMultipleNormalizer;
