"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var utilities_1 = require("../../utilities");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var AddToCartEvent_1 = require("./AddToCartEvent");
var AddToCartFavorisNormalizer = /** @class */ (function () {
    function AddToCartFavorisNormalizer() {
    }
    AddToCartFavorisNormalizer.prototype.normalize = function (elements, context) {
        var addToCartEvent = new AddToCartEvent_1.default();
        $(elements).each(function (index, value) {
            var gaDiv = $(value).closest('.consumable-product').find(constants_1.GA_CLASS);
            if (1 === gaDiv.length) {
                var item = ItemFactory_1.ItemFactory.createFromDataAttribute($(gaDiv));
                item.itemPricing = (0, utilities_1.getModePrice)();
                item.index = index + 1;
                addToCartEvent.itemCollection.addItem(item);
            }
        });
        return addToCartEvent;
    };
    AddToCartFavorisNormalizer.prototype.support = function (element, context) {
        return 'favoris' === context;
    };
    return AddToCartFavorisNormalizer;
}());
exports.default = AddToCartFavorisNormalizer;
