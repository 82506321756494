"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var utilities_1 = require("../../utilities");
var ItemFactory_1 = require("../dto/item/ItemFactory");
var AddToCartEvent_1 = require("./AddToCartEvent");
var AddToCartNormalizer = /** @class */ (function () {
    function AddToCartNormalizer() {
    }
    AddToCartNormalizer.prototype.normalize = function (elements, context) {
        var addToCartEvent = new AddToCartEvent_1.default();
        var parentDiv = $(elements).closest(':has(.ga)');
        if (1 !== parentDiv.length) {
            return addToCartEvent;
        }
        var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
        if (1 !== gaDiv.length) {
            return addToCartEvent;
        }
        var item = ItemFactory_1.ItemFactory.createFromDataAttribute(gaDiv);
        item.itemPricing = (0, utilities_1.getModePrice)();
        item.index = 1;
        addToCartEvent.itemCollection.addItem(item);
        return addToCartEvent;
    };
    AddToCartNormalizer.prototype.support = function (element, context) {
        return true;
    };
    return AddToCartNormalizer;
}());
exports.default = AddToCartNormalizer;
