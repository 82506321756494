"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FilterEvent = /** @class */ (function () {
    function FilterEvent() {
    }
    FilterEvent.prototype.export = function () {
        return {
            filter_type: this.filterType,
        };
    };
    return FilterEvent;
}());
exports.default = FilterEvent;
